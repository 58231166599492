@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
    transition: color 0.25s ease;
}

a:hover {
    color: #e45555;
}

body {
    margin: 0;
}

.button {
    padding: 10px 20px;
    margin: 0 30px;
    font-family: montserrat;
    color: #e2e2e2;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    border: solid 2px #e2e2e2;
    border-radius: 25px;
    transition: opacity 0.25s ease;
}

.button:hover {
    opacity: 0.5;
    color: #e2e2e2;
}

.justify {
    text-align: justify !important;
}

.left {
    text-align: left !important;
}

.light {
    color: #e2e2e2 !important;
}

.red {
    color: #e45555 !important;
}

@media only screen and (max-width: 767px) {
    .button {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

#nav_bar {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-self: stretch;
    width: 80%;
    padding: 0 10%;
    height: 15vh;
    z-index: 50;
}

#nav-container {
    padding-left: 1rem;
}

#nav_list_items_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

#nav_list {
    display: flex;
    list-style: none;
    padding: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.nav_list_item {
    padding: 0 10px;
}

.nav_list_item a {
    text-decoration: none;
    color: #e2e2e2;
    font-family: Montserrat;
    text-transform: uppercase;
    transition: color 0.25s ease;
}

.nav_list_item a:hover {
    color: #4eb69e;
}

.nav_list_button {
    color: #e2e2e2;
    background-color: #4eb69e;
    border-radius: 10px;
    border-color: transparent;
    border-width: 1px;
    font-weight: 800 !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.nav_list_button:hover {
    background-color: transparent;
}

@media only screen and (max-width: 991px) {

    #nav_list {
        display: flex;
        list-style: none;
        flex-direction: column;
        padding: 10px;
        border-radius: 5px;
        margin-right: 0;
        margin-left: auto;
        background-color: #191937;
        align-content: center;
        justify-content: center;
        align-items: center;
        -webkit-transform: translateX(150%);
                transform: translateX(150%);
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    }
    
    .nav_list_item {
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }

    #nav-logo-div {
        padding: 5px !important;
        margin: 0;
    }
}


/* react-burger-menu */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: white;
}
  
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #4eb69e;
}

/* Color/shape of close button cross */
.bm-cross {
    display: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: rgba(25, 25, 55, 0.8);
    padding: 1em 1em 0;
    font-size: 1.15em;
}
  
/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}
  
/* Individual item */
.bm-item {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* NAV STYLE */

#nav-item a {
    text-decoration: none;
    color: #e2e2e2;
    font-family: Montserrat;
    text-transform: uppercase;
    transition: color 0.25s ease;
    margin-top:5px;
    margin-bottom: 5px;
}

#nav-item a:hover {
    color: #4eb69e;
}

#nav-item-button {
    color: #e2e2e2;
    background-color: #4eb69e;
    border-radius: 10px;
    border-color: transparent;
    font-weight: 800 !important;
    border-width: 1px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    transition: background-color 0.3s, color 0.3s;
}

#nav-item-button:hover {
    background-color: transparent;
}

#nav-logo-div {
    display: flex;
    align-items: center;
    height: 90px;
    padding-top: 10px;
    text-align: center;
    height: 15vh;
}

#nav-logo {
    height: 10vh;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.nav-container-background {
    background: rgba(25, 25, 55, 0.8);
    transition: background-color 0.5s;
}
#banner_div {
    padding: 0 7%;
    /* min-height: 50rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(rgba(75, 83, 186, 0.3),rgba(255, 255, 255, 0.0)), url(/assets/img/banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

#banner_text_div {
    padding-top: 30rem;
    padding-bottom: 5rem;
    width: 66%;
}

#banner_text_div h1 {
    margin: 0;
    font-family: open sans;
    font-weight: 300;
    color: #e2e2e2;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
}

#buttons_div {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.button_main {
    background-color: #e45555;
    border: solid 2px #e45555;
}

@media only screen and (max-width: 991px) {

    #banner_div {
        background-attachment: scroll;
    }
}

@media only screen and (max-width: 767px) {

    #banner_div {
        padding: 0 7%;
        min-height: 0;
    }

    #banner_text_div {
        width: 100%;
        padding-bottom: 0;
    }

    #banner_text_div h1 {
        font-size: 28px;
        line-height: 30px;
    }
    
    #banner_div {
        padding: 0 5% 130px 5%;
    }

    #buttons_div {
        flex-direction: column;
    }
}


#notreoffre {
    padding: 50px 15%;
    margin: 0;
    margin-bottom: 30px;
    border-top: 5px solid #4b52ba;
}

.div_heading {
    margin: 40px 0;
    padding: 0;
    text-align: center;
    font-family: open sans;
    font-weight: 300;
    color: #525252;
    font-size: 42px;
    letter-spacing: 1px;
}

#features_list_div {
    display: flex;
    flex-wrap:wrap;
    padding: 0 5%;
    justify-content: flex-start;
    align-items: stretch;
}

.features_item_div {
    padding: 0 30px;
    margin: 30px 0;
    display: flex;
    align-items: center;
    width: 40%;
}

.features_image_div {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4b52ba;
    border-radius: 100%;
}

.features_image_div img {
    max-width: 50px;
    max-height: 50px;
}

.features_text_div {
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
}

.features_text_div h1 {
    padding: 0;
    margin: 0 0 10px 0;
    font-family: open sans;
    font-weight: 300;
    color: #525252;
    font-size: 28px;
}

.features_text_div p {
    text-align: left;
}

.portfolio_subheading {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    padding: 0 25%;
    margin: 40px 0;
}

.div_subheading {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    margin: 40px 0;
}

.div_subheading_features {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
}

@media only screen and (max-width: 991px) {

    #features_list_div {
        flex-direction: column;
    }
    
    .features_item_div {
        width: auto;
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
    
    .features_text_div {
        padding: 20px 0 0 0;
        text-align: center;
    }
    
    .features_text_div p {
        padding: 0 25%;    
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {

    #features_div {
        padding: 50px 5%;
    }
    
    .features_text_div p {
        padding: 0;
    }
    
    .div_subheading {
        padding: 0 0 20px 0;
        margin: 0;
    }

    .portfolio_subheading {
        padding: 0 5%;
    }
}
#portfolio {
    padding: 50px 15%;
    margin: 0;
    margin-bottom: 30px;
    background-color: #191937;
}

#realisations_list_div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 30px 200px;
}

.realisations_item_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 30px 30px;
}

.realisations_item_div img {
    max-width: 200px;
    border-radius: 30px;
    transition: -webkit-filter 0.3s, -webkit-transform 0.3s;
    transition: filter 0.3s, transform 0.3s;
    transition: filter 0.3s, transform 0.3s, -webkit-filter 0.3s, -webkit-transform 0.3s;
}

.realisations_item_div:hover img {
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.realisations_item_div h1 {
    text-align: center;
    font-size: 24px;
}


@media only screen and (max-width: 767px) {

    #realisations_list_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }    
    
    .realisations_item_div {
        padding: 20px 0;
    }

    #portfolio {
        padding: 50px 5%;
    }
    
}
#footer_column_div {
    display: flex;
    padding: 20px 12%;
    text-align: center;
}

.footer_column {
   width: 33%;
   padding: 0 50px;
}

.footer_column ul {
   list-style: none;
   padding: 0;
}

.footer_column ul li {
   padding: 10px 0;
   border-top: 1px solid #c4c4c4;
}

.footer_column ul li:first-child {
   border: none;
}

.footer {
   color: #888;
   font-size: 26px;
}

.footer_sub {
    color: #888;
}

 .footer_social_container {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #888;
    text-align: center;
    line-height: 60px;
    transition: border-color 0.3s;
    margin: 10px;
}

.footer_social {
    color: #888;
    transition: color 0.3s;
    width: 20px;
    height: 20px;
    padding: 10px;
}

.footer_social_container:hover {
    border-color: #333;
}

.footer_social_container:hover .footer_social {
    color: #333;
}

.footer_button {
    display: inline-flex;
    background-color: #4eb69e;
    font-weight: 500;
    font-size: 30px;
    border-style: solid;
    border-color: #4eb69e;
    border-radius: 15px;
    border-width: 2px;
    transition: background-color 0.3s, color 0.3s;
}

.footer_button:hover {
    background-color: white;
    color: #4eb69e !important;
}

.footer_button:hover a {
    color: #4eb69e !important;
}

.footer_button a {
    padding: 30px;
    color: white;
}

.div_subheading_footer {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    text-align: center;
}

.footer_copyrights {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    text-align: center;
    padding: 0 40px;
}


@media only screen and (max-width: 991px) {
    #footer_column_div {
        flex-direction: column;
        padding: 0;
    }
    
    .footer_column {
        width: auto;
    }
    
    .footer {
        text-align: center;
    }
    
    .footer_column ul li {
        text-align: center;
    }

    .footer_sub {
        padding: 10px 30px;
    }

    .div_subheading_footer {
        padding: 0 25px;
    }
}

@media only screen and (max-width: 767px) {
    
    #footer_column_div {
        padding: 0;
    }
    
    .footer_column {
        padding: 20px 0 0 0;
    }
}
.pricingcard {
    background-color: white;
    text-align: center;
    border-radius: 20px;
    width: 30%;
    margin: 1.5% 1.5%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.widepricingcard {
    width: 50% !important;
}

.widepricingcard h2{ 
    margin: 0 !important;
}

.widepricingcard p {
    /* color: black; */
    padding: 0 !important;
    /* font-size: 0.85rem; */
    /* font-weight: 200; */
}

.pricingcard_selected {
    box-shadow: 0px 0px 10px 10px #4eb69e;
}

.pricingcard h1 {
    color: black;
    margin: 1rem 3rem;
    font-size: 1.50rem;
}

.pricingcard h2 {
    color: black;
    margin: 2rem 0;
    font-size: 3rem;
}

.pricingcard p {
    color: black;
    padding: 0.25rem 1rem;
    font-size: 0.85rem;
    font-weight: 200;
}

.pricingcard h4 {
    color: white;
    background-color: #4eb69e;
    border-radius: 10px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 1rem;
    padding: 10px;
}

@media only screen and (max-width: 991px) {
    .pricingcard {
        width: 90%;
        margin: 1rem auto;
    }

    .widepricingcard {
        width: 90% !important;
    }

    .pricingcard h1 {
        margin-left: 0;
        margin-right: 0;
    }
}
#pricing {
    background-color: #191937;
    color:white;
    text-align: center;
    padding-top: 15vh;
    padding-bottom: 5rem;
}

#pricing_title {
    padding: 3rem 0 1.5rem;
}

#pricing_title h1 {
    margin: 0;
    font-weight: 400;
    font-size: 3rem;
    text-transform: uppercase;
}

#pricing_subtitle {
    padding: 1.5rem 0 1.5rem;
}

#pricing_subtitle h3 {
    font-weight: 200;
    font-size: 1.25rem;
}

#pricing_cost {
    background: rgba(25, 25, 55, 0.9);
    padding: 0.5rem 0;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}

#pricing_cost h2 {
    padding: 0;
    margin: 0.5rem 0;
    font-weight: 300;
}

#pricing_cost b {
    font-weight: 800;
}

#pricing_cost h3 {
    padding: 0;
    margin: 0.5rem 0;
    font-weight: 300;
}

#pricingcard_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;
    margin: 0 15%;
}

#pricingcard_container_empty {
    display: flex;
    justify-content: center;
    width: 100%;
}

#pricing h5 {
    padding-top: 3rem;
    font-size: 1.25rem;
    font-weight: 300;
}

@media only screen and (max-width: 991px) {
    #pricingcard_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }    
}
