.pricingcard {
    background-color: white;
    text-align: center;
    border-radius: 20px;
    width: 30%;
    margin: 1.5% 1.5%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.widepricingcard {
    width: 50% !important;
}

.widepricingcard h2{ 
    margin: 0 !important;
}

.widepricingcard p {
    /* color: black; */
    padding: 0 !important;
    /* font-size: 0.85rem; */
    /* font-weight: 200; */
}

.pricingcard_selected {
    box-shadow: 0px 0px 10px 10px #4eb69e;
}

.pricingcard h1 {
    color: black;
    margin: 1rem 3rem;
    font-size: 1.50rem;
}

.pricingcard h2 {
    color: black;
    margin: 2rem 0;
    font-size: 3rem;
}

.pricingcard p {
    color: black;
    padding: 0.25rem 1rem;
    font-size: 0.85rem;
    font-weight: 200;
}

.pricingcard h4 {
    color: white;
    background-color: #4eb69e;
    border-radius: 10px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 1rem;
    padding: 10px;
}

@media only screen and (max-width: 991px) {
    .pricingcard {
        width: 90%;
        margin: 1rem auto;
    }

    .widepricingcard {
        width: 90% !important;
    }

    .pricingcard h1 {
        margin-left: 0;
        margin-right: 0;
    }
}