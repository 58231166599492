#footer_column_div {
    display: flex;
    padding: 20px 12%;
    text-align: center;
}

.footer_column {
   width: 33%;
   padding: 0 50px;
}

.footer_column ul {
   list-style: none;
   padding: 0;
}

.footer_column ul li {
   padding: 10px 0;
   border-top: 1px solid #c4c4c4;
}

.footer_column ul li:first-child {
   border: none;
}

.footer {
   color: #888;
   font-size: 26px;
}

.footer_sub {
    color: #888;
}

 .footer_social_container {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #888;
    text-align: center;
    line-height: 60px;
    transition: border-color 0.3s;
    margin: 10px;
}

.footer_social {
    color: #888;
    transition: color 0.3s;
    width: 20px;
    height: 20px;
    padding: 10px;
}

.footer_social_container:hover {
    border-color: #333;
}

.footer_social_container:hover .footer_social {
    color: #333;
}

.footer_button {
    display: inline-flex;
    background-color: #4eb69e;
    font-weight: 500;
    font-size: 30px;
    border-style: solid;
    border-color: #4eb69e;
    border-radius: 15px;
    border-width: 2px;
    transition: background-color 0.3s, color 0.3s;
}

.footer_button:hover {
    background-color: white;
    color: #4eb69e !important;
}

.footer_button:hover a {
    color: #4eb69e !important;
}

.footer_button a {
    padding: 30px;
    color: white;
}

.div_subheading_footer {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    text-align: center;
}

.footer_copyrights {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    text-align: center;
    padding: 0 40px;
}


@media only screen and (max-width: 991px) {
    #footer_column_div {
        flex-direction: column;
        padding: 0;
    }
    
    .footer_column {
        width: auto;
    }
    
    .footer {
        text-align: center;
    }
    
    .footer_column ul li {
        text-align: center;
    }

    .footer_sub {
        padding: 10px 30px;
    }

    .div_subheading_footer {
        padding: 0 25px;
    }
}

@media only screen and (max-width: 767px) {
    
    #footer_column_div {
        padding: 0;
    }
    
    .footer_column {
        padding: 20px 0 0 0;
    }
}