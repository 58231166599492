
#nav_bar {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-self: stretch;
    width: 80%;
    padding: 0 10%;
    height: 15vh;
    z-index: 50;
}

#nav-container {
    padding-left: 1rem;
}

#nav_list_items_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

#nav_list {
    display: flex;
    list-style: none;
    padding: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.nav_list_item {
    padding: 0 10px;
}

.nav_list_item a {
    text-decoration: none;
    color: #e2e2e2;
    font-family: Montserrat;
    text-transform: uppercase;
    transition: color 0.25s ease;
}

.nav_list_item a:hover {
    color: #4eb69e;
}

.nav_list_button {
    color: #e2e2e2;
    background-color: #4eb69e;
    border-radius: 10px;
    border-color: transparent;
    border-width: 1px;
    font-weight: 800 !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.nav_list_button:hover {
    background-color: transparent;
}

@media only screen and (max-width: 991px) {

    #nav_list {
        display: flex;
        list-style: none;
        flex-direction: column;
        padding: 10px;
        border-radius: 5px;
        margin-right: 0;
        margin-left: auto;
        background-color: #191937;
        align-content: center;
        justify-content: center;
        align-items: center;
        transform: translateX(150%);
        transition: transform 0.3s ease;
    }
    
    .nav_list_item {
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }

    #nav-logo-div {
        padding: 5px !important;
        margin: 0;
    }
}


/* react-burger-menu */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: white;
}
  
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #4eb69e;
}

/* Color/shape of close button cross */
.bm-cross {
    display: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: rgba(25, 25, 55, 0.8);
    padding: 1em 1em 0;
    font-size: 1.15em;
}
  
/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}
  
/* Individual item */
.bm-item {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* NAV STYLE */

#nav-item a {
    text-decoration: none;
    color: #e2e2e2;
    font-family: Montserrat;
    text-transform: uppercase;
    transition: color 0.25s ease;
    margin-top:5px;
    margin-bottom: 5px;
}

#nav-item a:hover {
    color: #4eb69e;
}

#nav-item-button {
    color: #e2e2e2;
    background-color: #4eb69e;
    border-radius: 10px;
    border-color: transparent;
    font-weight: 800 !important;
    border-width: 1px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    transition: background-color 0.3s, color 0.3s;
}

#nav-item-button:hover {
    background-color: transparent;
}

#nav-logo-div {
    display: flex;
    align-items: center;
    height: 90px;
    padding-top: 10px;
    text-align: center;
    height: 15vh;
}

#nav-logo {
    height: 10vh;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.nav-container-background {
    background: rgba(25, 25, 55, 0.8);
    transition: background-color 0.5s;
}