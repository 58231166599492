#banner_div {
    padding: 0 7%;
    /* min-height: 50rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(rgba(75, 83, 186, 0.3),rgba(255, 255, 255, 0.0)), url(/assets/img/banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

#banner_text_div {
    padding-top: 30rem;
    padding-bottom: 5rem;
    width: 66%;
}

#banner_text_div h1 {
    margin: 0;
    font-family: open sans;
    font-weight: 300;
    color: #e2e2e2;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
}

#buttons_div {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.button_main {
    background-color: #e45555;
    border: solid 2px #e45555;
}

@media only screen and (max-width: 991px) {

    #banner_div {
        background-attachment: scroll;
    }
}

@media only screen and (max-width: 767px) {

    #banner_div {
        padding: 0 7%;
        min-height: 0;
    }

    #banner_text_div {
        width: 100%;
        padding-bottom: 0;
    }

    #banner_text_div h1 {
        font-size: 28px;
        line-height: 30px;
    }
    
    #banner_div {
        padding: 0 5% 130px 5%;
    }

    #buttons_div {
        flex-direction: column;
    }
}
