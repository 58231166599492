#pricing {
    background-color: #191937;
    color:white;
    text-align: center;
    padding-top: 15vh;
    padding-bottom: 5rem;
}

#pricing_title {
    padding: 3rem 0 1.5rem;
}

#pricing_title h1 {
    margin: 0;
    font-weight: 400;
    font-size: 3rem;
    text-transform: uppercase;
}

#pricing_subtitle {
    padding: 1.5rem 0 1.5rem;
}

#pricing_subtitle h3 {
    font-weight: 200;
    font-size: 1.25rem;
}

#pricing_cost {
    background: rgba(25, 25, 55, 0.9);
    padding: 0.5rem 0;
    position: sticky;
    bottom: 0;
}

#pricing_cost h2 {
    padding: 0;
    margin: 0.5rem 0;
    font-weight: 300;
}

#pricing_cost b {
    font-weight: 800;
}

#pricing_cost h3 {
    padding: 0;
    margin: 0.5rem 0;
    font-weight: 300;
}

#pricingcard_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;
    margin: 0 15%;
}

#pricingcard_container_empty {
    display: flex;
    justify-content: center;
    width: 100%;
}

#pricing h5 {
    padding-top: 3rem;
    font-size: 1.25rem;
    font-weight: 300;
}

@media only screen and (max-width: 991px) {
    #pricingcard_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }    
}