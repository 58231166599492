
#notreoffre {
    padding: 50px 15%;
    margin: 0;
    margin-bottom: 30px;
    border-top: 5px solid #4b52ba;
}

.div_heading {
    margin: 40px 0;
    padding: 0;
    text-align: center;
    font-family: open sans;
    font-weight: 300;
    color: #525252;
    font-size: 42px;
    letter-spacing: 1px;
}

#features_list_div {
    display: flex;
    flex-wrap:wrap;
    padding: 0 5%;
    justify-content: flex-start;
    align-items: stretch;
}

.features_item_div {
    padding: 0 30px;
    margin: 30px 0;
    display: flex;
    align-items: center;
    width: 40%;
}

.features_image_div {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4b52ba;
    border-radius: 100%;
}

.features_image_div img {
    max-width: 50px;
    max-height: 50px;
}

.features_text_div {
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
}

.features_text_div h1 {
    padding: 0;
    margin: 0 0 10px 0;
    font-family: open sans;
    font-weight: 300;
    color: #525252;
    font-size: 28px;
}

.features_text_div p {
    text-align: left;
}

.portfolio_subheading {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    padding: 0 25%;
    margin: 40px 0;
}

.div_subheading {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    margin: 40px 0;
}

.div_subheading_features {
    display: block;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
}

@media only screen and (max-width: 991px) {

    #features_list_div {
        flex-direction: column;
    }
    
    .features_item_div {
        width: auto;
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
    
    .features_text_div {
        padding: 20px 0 0 0;
        text-align: center;
    }
    
    .features_text_div p {
        padding: 0 25%;    
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {

    #features_div {
        padding: 50px 5%;
    }
    
    .features_text_div p {
        padding: 0;
    }
    
    .div_subheading {
        padding: 0 0 20px 0;
        margin: 0;
    }

    .portfolio_subheading {
        padding: 0 5%;
    }
}