#portfolio {
    padding: 50px 15%;
    margin: 0;
    margin-bottom: 30px;
    background-color: #191937;
}

#realisations_list_div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 30px 200px;
}

.realisations_item_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 30px 30px;
}

.realisations_item_div img {
    max-width: 200px;
    border-radius: 30px;
    transition: filter 0.3s, transform 0.3s;
}

.realisations_item_div:hover img {
    filter: brightness(50%);
    transform: scale(1.05);
}

.realisations_item_div h1 {
    text-align: center;
    font-size: 24px;
}


@media only screen and (max-width: 767px) {

    #realisations_list_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }    
    
    .realisations_item_div {
        padding: 20px 0;
    }

    #portfolio {
        padding: 50px 5%;
    }
    
}